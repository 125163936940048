import { createAction, props } from "@ngrx/store";
import { CustomFormBuilder, FormBuilderSection } from "@dataFormatter/dto/form-build-dto";
export const getFormBuild = createAction("getFormBuild");
export const submitForm = createAction("submitForm", props<{ form: CustomFormBuilder }>());
export const postFormType = createAction("postFormType", props<{ formType: string }>());
export const postFacilityName = createAction("postFacilityName", props<{ facilityName: string }>());
export const postFormTitle = createAction("postFormTitle", props<{ formTitle: string }>());
export const setCompanyName = createAction("setCompanyName", props<{ companyName: string }>());
export const postSections = createAction("postSections", props<{ sections: FormBuilderSection[] }>());
export const postForm = createAction("postForm", props<{ form: CustomFormBuilder }>());
export const postBackgroundColor = createAction("postBackgroundColor", props<{ backgroundColor: string }>());
export const postBackgroundStyle = createAction("postBackgroundStyle", props<{ backgroundStyle: string }>());
export const postButtonColor = createAction("postButtonColor", props<{ buttonColor: string }>());
export const postLanguageSupportToggle = createAction(
  "postLanguageSupportToggle",
  props<{ toggleLanguage: boolean }>(),
);
export const postConsentPageToggle = createAction("postConsentPageToggle", props<{ toggleConsent: boolean }>());
export const postLanguageOptions = createAction("postLanguageOptions", props<{ languageOptions: string[] }>());
export const resetStore = createAction("resetStore");

// export const updateSectioin = createAction("postSections", props<{ sections: FormBuilderSection[] }>());
